
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { Student } from '../models/students/student.class';
import { cloneDeep } from 'lodash';
import { EntityListColumn, EntityListRowAction } from '../models/shared/entity-list.interfaces';
import EntityList from './EntityList.vue';
import StudentForm from './StudentForm.vue';
import { BModal } from 'bootstrap-vue';
import { namespace } from 'vuex-class';
import { Registration } from '../models/registrations/registration.class';
import { User } from '../models/users/user.class';
import { UserRole } from '../models/users/user-role.enum';

const registrationModule = namespace('registration');
const authModule = namespace('auth');

@Component({
  components: {
    EntityList,
    Multiselect,
    StudentForm,
  },
})
export default class CourseRegisterStudents extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  registration!: Registration;

  @authModule.Getter('authenticatedUser')
  authenticatedUser!: User;

  @registrationModule.Action('fetchStudents')
  fetchStudents!: () => Promise<Student[]>;

  @registrationModule.Action('createStudent')
  createStudent!: (student: Student) => Promise<Student>;

  @registrationModule.Action('updateStudent')
  updateStudent!: (student: Student) => Promise<Student>;

  @registrationModule.Getter('students')
  students!: Student[];

  $refs!: Vue['$refs'] & {
    removeStudentModal: BModal;
    addStudentModal: BModal;
    editStudentModal: BModal;
  };

  studentToBeRemoved: Student | null = null;
  studentsToBeAdded: Student[] = [];

  isCreatingStudent = false;
  creatingStudent: Student | null = null;

  saveStudentIsLoading = false;
  updateStudentIsLoading = false;

  editingStudent: Student | null = null;

  nextStepClicked = false;

  get listColumns(): Array<EntityListColumn<Student>> {
    return [
      {
        field: 'firstName',
        label: `${this.$t('forms.firstName')}`,
      },
      {
        field: 'lastName',
        label: `${this.$t('forms.lastName')}`,
      },
      {
        field: 'email',
        label: `${this.$t('forms.email')}`,
      },
      {
        field: 'phone',
        label: `${this.$t('forms.phone')}`,
      },
    ];
  }

  get listActions(): Array<EntityListRowAction<Student>> {
    return [
      {
        key: 'edit',
        label: `${this.$t('general.edit')}`,
        showAction: () => this.canEditStudents,
        doAction: (action, item) => this.showEditStudentModal(item),
      },
      {
        key: 'delete',
        label: `${this.$t('general.delete')}`,
        showAction: () => true,
        doAction: (action, item) => this.showRemoveStudentModal(item),
      },
    ];
  }

  get canEditStudents() {
    return this.authenticatedUser.role === UserRole.ADMIN;
  }

  get showErrorMessage() {
    return this.nextStepClicked && !this.registration.students.length;
  }

  showRemoveStudentModal(studentToRemove: Student) {
    this.studentToBeRemoved = cloneDeep(studentToRemove);
    this.$refs.removeStudentModal.show();
  }

  removeStudentConfirmed() {
    this.registration.students = this.registration.students.filter(
      student => student._id !== (this.studentToBeRemoved as Student)._id,
    );
  }

  onRemoveStudentModalClosing() {
    this.studentToBeRemoved = null;
  }

  showAddStudentModal() {
    this.studentsToBeAdded = cloneDeep(this.registration.students);
    this.$refs.addStudentModal.show();
  }

  addStudentConfirmed() {
    this.registration.students = cloneDeep(this.studentsToBeAdded);
  }

  onAddStudentModalClosing() {
    this.studentsToBeAdded = [];
    this.creatingStudent = null;
    this.isCreatingStudent = false;
  }

  studentsCustomLabel(student: Student) {
    return `${student.firstName} ${student.lastName}`;
  }

  onCreateStudent(newStudentName: string) {
    this.isCreatingStudent = true;

    const [firstName, ...lastName] = newStudentName.trim().split(' ');
    this.creatingStudent = new Student(firstName, lastName.join(' '));
  }

  onCancelCreateStudent() {
    this.isCreatingStudent = false;
    this.creatingStudent = null;
  }

  async onSaveStudent() {
    this.saveStudentIsLoading = true;

    if (this.creatingStudent) {
      if (this.authenticatedUser.customer && typeof this.authenticatedUser.customer !== 'string') {
        this.creatingStudent.customer = this.authenticatedUser.customer._id as string;
      }

      const createdStudent = await this.createStudent(this.creatingStudent);
      if (createdStudent) {
        this.studentsToBeAdded.push(createdStudent);
        this.isCreatingStudent = false;
        this.creatingStudent = null;
      }
    }

    this.saveStudentIsLoading = false;
  }

  showEditStudentModal(studentToEdit: Student) {
    this.editingStudent = cloneDeep(studentToEdit);
    this.$refs.editStudentModal.show();
  }

  onCancelEditStudent() {
    this.$refs.editStudentModal.hide();
  }

  onEditStudentModalClosing() {
    this.editingStudent = null;
  }

  async onUpdateStudent() {
    this.updateStudentIsLoading = true;

    if (this.editingStudent) {
      const updatedStudent = await this.updateStudent(this.editingStudent);
      if (updatedStudent) {
        this.$refs.editStudentModal.hide();
        this.registration.students = this.registration.students.map(student =>
          student._id === updatedStudent._id ? updatedStudent : student,
        );
      }
    }

    this.updateStudentIsLoading = false;
  }

  @Emit('next-step')
  onNextStep() {
    this.nextStepClicked = true;
    return true;
  }

  async created() {
    await this.fetchStudents();
  }
}
