import { PaymentStatus } from '@/enums/payment-status.enum';
import { BaseModel } from '../base-model.class';
import Session from '../sessions/session.class';
import { Student } from '../students/student.class';

export class Registration extends BaseModel {
  date = new Date();
  paymentId?: string;
  paymentStatus?: PaymentStatus;
  paymentMethod?: string;
  paymentCheckoutUrl?: string;
  paymentUrl?: string;
  paymentUuid?: string;
  session?: Session | string;
  customer = '';
  customerReference = '';
  students: Student[] = [];
  registeringUser = '';
  sendPaymentToUser?: boolean;
  sendConfirmationToUser?: boolean;
  sendConfirmationToStudents?: boolean;
  isPaid?: boolean;
  isPartnerSession?: boolean;
}
