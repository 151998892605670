import { BaseModel } from '../base-model.class';
import { Registration } from '../registrations/registration.class';

export class Customer extends BaseModel {
  name = '';
  street = '';
  zip = '';
  city = '';
  country = '';
  vat?: string;
  refNumber = '';
  user?: string;
  language?: string;
  acornId?: string;
  archived?: boolean;
  registrations: Registration[] = [];
}
