
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CourseRegisterConfirmationSummaryItem extends Vue {
  @Prop({
    type: String,
    default: '0',
  })
  headerBottomMargin!: string;
}
