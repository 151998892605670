
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { EntityListColumn, EntityListRowAction } from '../models/shared/entity-list.interfaces';

@Component({
  components: {},
})
export default class EntityList extends Vue {
  @Prop({
    type: Array,
  })
  data!: never[];

  @Prop({
    type: Array,
  })
  columns!: Array<EntityListColumn<never>>;

  @Prop({
    type: Array,
  })
  actions!: Array<EntityListRowAction<never>>;

  get needActionColumn() {
    return this.actions && this.actions.length;
  }

  rowHasActions(item: never) {
    return (
      this.needActionColumn &&
      this.actions.findIndex((action: EntityListRowAction<never>) => action.showAction(item)) !== -1
    );
  }

  doAction(action: EntityListRowAction<never>, rowIndex: number) {
    action.doAction(action, this.data[rowIndex]);
  }

  @Emit('row-clicked')
  onRowClick(index: number) {
    return this.data[index];
  }
}
