
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import FormInput from '../components/FormInput.vue';
import { Registration } from '../models/registrations/registration.class';
import { namespace } from 'vuex-class';
import { User } from '../models/users/user.class';
import { Customer } from '../models/customers/customer.class';
import { cloneDeep } from 'lodash';

const authModule = namespace('auth');
const usersModule = namespace('users');

@Component({
  components: {
    ValidationObserver,
    FormInput,
  },
})
export default class CourseRegisterInvoice extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  registration!: Registration;

  @authModule.Getter('authenticatedUser')
  currentUser!: User;

  @usersModule.Action('updateCustomer')
  updateCustomer!: (customer: Customer) => Promise<Customer>;

  $refs!: Vue['$refs'] & {
    observer: InstanceType<typeof ValidationObserver>;
  };

  customer: Customer = new Customer();

  isLoading = false;

  @Watch('currentUser', { immediate: true, deep: true })
  onUserChanged() {
    this.customer = cloneDeep(this.currentUser.customer as Customer);
  }

  async onSave() {
    this.isLoading = true;

    const updatedCustomer = await this.updateCustomer(this.customer);
    if (updatedCustomer) {
      this.onNextStep();
    }

    this.isLoading = false;
  }

  // This method is used in the parent component to check if the form is valid
  customerFormErrors() {
    const fieldsWithValidation = Object.keys(this.$refs.observer.errors);
    let errors: string[] = [];
    fieldsWithValidation.map(field => {
      if (this.$refs.observer.errors[field].length) {
        errors = [...errors, ...this.$refs.observer.errors[field]];
      }
    });

    return errors;
  }

  @Emit('next-step')
  onNextStep() {
    return true;
  }
}
