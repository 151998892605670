
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { translateApi } from '@/utils/translate-api';
import { Registration } from '../models/registrations/registration.class';
import CourseRegisterConfirmationSummaryItem from './CourseRegisterConfirmationSummaryItem.vue';
import Session from '../models/sessions/session.class';
import Course from '../models/courses/course.class';
import moment from 'moment';

@Component({
  components: {
    CourseRegisterConfirmationSummaryItem,
  },
  filters: {
    translateApi,
  },
})
export default class CourseRegisterConfirmation extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  course!: Course;

  @Prop({
    type: Object,
    required: true,
  })
  session!: Session;

  @Prop({
    type: Object,
    required: true,
  })
  registration!: Registration;

  @Prop({
    type: Boolean,
  })
  isLoading!: boolean;

  get totalExclVat() {
    return Math.round(this.session.price * this.registration.students.length * 100) / 100;
  }

  get vat() {
    return Math.round(this.session.price * this.registration.students.length * 21) / 100;
  }

  get totalInclVat() {
    return Math.round(this.session.price * this.registration.students.length * 121) / 100;
  }

  get formattedDate() {
    moment.locale('nl-be');
    if (this.session.startDate) {
      return moment(this.session.startDate).format('L');
    }
    return '';
  }

  get locationString() {
    if (this.session.isOnline) {
      return this.$t('course.onlineLocation');
    }

    if (this.session.location) {
      return `${translateApi(this.session.location.name, this.$i18n.locale)}, ${translateApi(
        this.session.location.address,
        this.$i18n.locale,
      )}, ${this.session.location.zipCode} ${translateApi(
        this.session.location.city,
        this.$i18n.locale,
      )}`;
    }
    return '';
  }

  get priceItems() {
    return [
      {
        header: this.$t('course.register.priceLabel'),
        body: `€${this.session.price}`,
      },
      {
        header: this.$t('course.register.totalExVat'),
        body: `€${this.totalExclVat}`,
      },
      {
        header: this.$t('course.register.VAT'),
        body: `€${this.vat}`,
      },
      {
        header: this.$t('course.register.totalInclVat'),
        body: `€${this.totalInclVat}`,
      },
    ];
  }

  @Emit('confirm')
  onConfirmEnrollment() {
    return true;
  }
}
