
import { Component, Vue, Emit } from 'vue-property-decorator';
import { CourseRegistrationType } from '../enums/course-registration-type.enum';

@Component({
  components: {},
})
export default class CourseRegisterType extends Vue {
  courseRegistrationType = CourseRegistrationType;

  @Emit('select-type')
  onSelectType(type: CourseRegistrationType) {
    return type;
  }
}
