
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CourseRegisterType from '../components/CourseRegisterType.vue';
import CourseRegisterStudents from '../components/CourseRegisterStudents.vue';
import CourseRegisterInvoice from '../components/CourseRegisterInvoice.vue';
import CourseRegisterConfirmation from '../components/CourseRegisterConfirmation.vue';
import CourseRegisterThanks from '../components/CourseRegisterThanks.vue';
import Course from '../models/courses/course.class';
import { Registration } from '../models/registrations/registration.class';
import { UsePageHeaderMixin } from '../mixins/use-page-header.mixin';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';
import { translateApi } from '../utils/translate-api';
import Session from '../models/sessions/session.class';
import { CourseRegistrationType } from '../enums/course-registration-type.enum';
import { CourseRegistrationTabs } from '../enums/course-registration-tabs.enum';
import { Student } from '../models/students/student.class';
import { User } from '../models/users/user.class';
import { BvEvent } from 'bootstrap-vue';

const coursesModule = namespace('courses');
const registrationModule = namespace('registration');
const authModule = namespace('auth');
const appModule = namespace('app');

@Component({
  components: {
    CourseRegisterType,
    CourseRegisterStudents,
    CourseRegisterInvoice,
    CourseRegisterConfirmation,
    CourseRegisterThanks,
  },
  filters: {
    translateApi,
  },
})
export default class CourseRegister extends Mixins(UsePageHeaderMixin, UseCurrentLocaleMixin) {
  @coursesModule.Getter('one')
  course!: Course;

  @coursesModule.Action('fetchOne')
  fetchCourseById!: (id: string) => Course;

  @coursesModule.Action('clearOne')
  clearOne!: () => void;

  @registrationModule.Getter('selectedSession')
  selectedSession!: Session;

  @registrationModule.Action('fetchSession')
  fetchSessionById!: (id: string) => Session;

  @registrationModule.Action('fetchStudentFromCurrentUser')
  fetchStudentFromCurrentUser!: () => Promise<Student>;

  @registrationModule.Action('createStudentFromCurrentUser')
  createStudentFromCurrentUser!: () => Promise<Student>;

  @registrationModule.Getter('currentUserStudent')
  currentUserStudent!: Student;

  @registrationModule.Action('createRegistration')
  createRegistration!: (registration: Registration) => Promise<Registration>;

  @authModule.Getter('isLoggedIn')
  isLoggedIn!: boolean;

  @authModule.Getter('authenticatedUser')
  authenticatedUser!: User;

  @appModule.Getter('windowWidth')
  viewWidth!: (width: number) => number;

  registrationType: CourseRegistrationType | null = null;
  tabIndex: number | null = 0;

  registration: Registration = new Registration();
  submitRegistrationIsLoading = false;

  $refs!: Vue['$refs'] & {
    courseRegisterInvoice: InstanceType<typeof CourseRegisterInvoice>;
  };

  @Watch('authenticatedUser', { immediate: true })
  onCurrentUserChanged() {
    if (this.authenticatedUser.customer && typeof this.authenticatedUser.customer !== 'string') {
      this.registration.customer = this.authenticatedUser.customer._id as string;
      this.registration.registeringUser = this.authenticatedUser._id as string;
    }
  }

  get headerHtml() {
    if (this.course) {
      return `<h3 class="text-white">${translateApi(this.course.title, this.$i18n.locale)}</h3>`;
    } else return '';
  }

  async onSelectType(type: CourseRegistrationType) {
    if (type === CourseRegistrationType.Myself) {
      if (!this.currentUserStudent) {
        await this.createStudentFromCurrentUser();
      }

      this.registration.students.push(this.currentUserStudent);
      this.tabIndex = 1;
    }

    this.registrationType = type;
  }

  // Blocks changing tabs if errors according to each tab
  goToNextTab(nextTabIndex: number, currentTabIndex: number, event: BvEvent) {
    if (currentTabIndex === CourseRegistrationTabs.Students && !this.registration.students.length) {
      event.preventDefault();
    }

    const customerHasErrors = !!(
      this.$refs.courseRegisterInvoice &&
      this.$refs.courseRegisterInvoice.customerFormErrors().length
    );
    if (currentTabIndex === CourseRegistrationTabs.InvoiceInfo && customerHasErrors) {
      event.preventDefault();
    }

    if (currentTabIndex === CourseRegistrationTabs.Thanks) {
      event.preventDefault();
    }
  }

  async onConfirmEnrollment() {
    this.submitRegistrationIsLoading = true;

    // Add extra registration params
    this.registration.sendConfirmationToUser = true;
    this.registration.sendPaymentToUser = !this.selectedSession.isPartnerSession;
    this.registration.sendConfirmationToStudents = !this.selectedSession.isPartnerSession;
    this.registration.isPaid = this.selectedSession.isPartnerSession;
    this.registration.isPartnerSession = this.selectedSession.isPartnerSession;

    const createdRegistration = await this.createRegistration(this.registration);
    if (createdRegistration) {
      this.tabIndex = 3;
    }

    this.submitRegistrationIsLoading = false;
  }

  async created() {
    if (!this.isLoggedIn) {
      return this.$router.push({
        name: 'login',
        query: { redirect: this.$route.fullPath },
      });
    } else {
      await Promise.all([
        this.fetchCourseById(this.$route.params.id),
        this.fetchSessionById(this.$route.params.sessionId),
      ]);

      await this.fetchStudentFromCurrentUser();

      if (!this.course || !this.course._id || !this.selectedSession) {
        this.$router.push({ name: 'pageNotFound' });
      }

      this.registration.session = this.selectedSession._id as string;
    }
  }

  destroyed() {
    this.clearOne();
  }
}
