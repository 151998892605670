
import { Component, Vue, Model, Emit, Prop } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import FormInput from './FormInput.vue';
import { Student } from '../models/students/student.class';
import { BIcon, BIconQuestionCircleFill } from 'bootstrap-vue';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    FormInput,
    BIcon,
    BIconQuestionCircleFill,
  },
})
export default class StudentForm extends Vue {
  @Model('change', {
    type: Object,
  })
  student!: Student;

  @Prop({
    type: Boolean,
  })
  isLoading!: boolean;

  @Emit('cancel')
  onCancel() {
    return this.student;
  }

  @Emit('save')
  onSave() {
    return this.student;
  }
  trimInput(value: string) {
    this.student.phone = value.split(' ').join('');
  }
}
