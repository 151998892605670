
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';
import { Registration } from '@/models/registrations/registration.class';
import Session from '@/models/sessions/session.class';

@Component({
  components: {},
})
export default class CourseRegisterThanks extends Mixins(UseCurrentLocaleMixin) {
  @Prop({
    type: Object,
    required: true,
  })
  session!: Session;

  @Prop({
    type: Object,
    required: true,
  })
  registration!: Registration;
}
